<template>
  <div class="overview">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="alarms">
            <p-button button-type="ghost" @click="isAlarmShow">
              <p-icon icon="alarm-add" size="md" color="fg-secondary" />
            </p-button>

            <p-button button-type="ghost" @click="isShowEasyTrade">
              <p-icon icon="chevron-right" size="md" color="fg-secondary" />
            </p-button>
          </div>
        </div>
      </div>

      <div class="row">
        <p-button @click="router.push('financial-history/buy-sell')">Tüm işlemler</p-button>
        <p-button button-type="ghost" @click="openNotification = !openNotification">
          <div class="place-items-center gap-8">
            <p class="t-fg-primary" v-text="$t('general.announcements')" />
            <p-icon icon="notifications" size="md" color="fg-primary" />
          </div>
        </p-button>

        <div v-if="openNotification">
          <announcements @on-close="openNotification = false" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const router = useRouter()
const openNotification = ref<boolean>(false)

const isAlarmShow = () => {
  smartRouter.go({ query: { overlay: 'alarms' } })
}

const isShowEasyTrade = () => {
  smartRouter.go({ query: { overlay: 'trade' } })
}
</script>
